<template>
    <div class="Growth">
        <div class="title-card">
            <img src="@/assets/czz.png" class="img" alt="" />
            <div class="text">{{ isId ? "徒弟" : "我" }}的成长值</div>
        </div>
        <Ranking
            :isIntegral="false"
            :rankingData="rankingData"
            :my_growth="my_growth"
            :myRanking="myRanking"
            :isId="isId"
            :record="record"
            @loadRecord="loadRecord"
            :avatar="avatar || require('@/assets/defulatAvatar.png')"
            :name="name"
        ></Ranking>
    </div>
</template>

<script>
    import Ranking from "@/components/Ranking.vue";
    export default {
        name: "Growth",
        components: {
            Ranking,
        },
        data() {
            return {
                rankingData: [], //成长值数据
                my_growth: 0, //我的成长值
                myRanking: 0, //我的成长值排行
                record: [], //成长记录
                has_next: false, //是否有下一页
                page: 1,
                isId: false, //是否为徒弟的成长值
                avatar: "", //徒弟头像
                name: "", //徒弟名字
            };
        },

        computed: {
            //徒弟id
            id: function () {
                return this.$store.state.user.id;
            },
        },

        watch: {
            id: function () {
                this.getRanking();
                this.getRecord();
            },
        },

        mounted() {
            window.localStorage.setItem("myNavActive", -2);
            this.$store.commit("switchMyNav", -2);
            this.isId = this.$route.query.ranking ? true : false;
            this.getRanking();
            this.getRecord();
        },

        methods: {
            //成长排行榜
            getRanking() {
                this.$https
                    .get("/api/my_master/growth_rank", {
                        kind: "total",
                        id: this.id,
                    })
                    .then((res) => {
                        this.rankingData = res.data.list;
                        this.my_growth = res.data.my_growth;
                        this.myRanking = res.data.ranking;
                        this.name = res.data.name;
                        this.avatar = res.data.avatar;
                    });
            },
            //成长记录
            getRecord(page = 1) {
                this.$https
                    .get("/api/my_master/growth_list", {
                        page,
                        limit: 10,
                        id: this.id || 0,
                    })
                    .then((res) => {
                        this.record = [...this.record, ...res.data.list];
                        this.has_next = res.data.has_next;
                    });
            },

            loadRecord() {
                if (!this.has_next) return;
                this.getRecord(this.page + 1);
                this.page++;
            },
        },
    };
</script>

<style lang="less" scoped>
.Growth {
    width: 100%;
    .title-card {
        width: 100%;
        background: #ffffff;
        border-radius: 6px;
        padding: 22px 19px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .img {
            width: 34px;
            height: 28px;
        }
        .text {
            font-size: 18px;
            font-weight: 500;
            color: #2d2d2d;
            margin-left: 12px;
        }
    }
}
</style>